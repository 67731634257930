import React from 'react'
import BlogCard from '../components/BlogCard'
import Hero from '../components/Hero'
import Meta from '../components/Meta'
import { graphql } from 'gatsby'

export const query = graphql`
query MyQuery {
  sanityBlogPage {
    seo {
      title
      description
      image {
        asset {
          url
        }
      }
    }
    hero {
      headline
      _rawImage
      link
      subHeadline
    }
    blogPosts {
			title
      _rawContent(resolveReferences: {maxDepth: 10})
      _rawHero
      slug {
        current
      }
      seo {
        description
      }
    }
  }
}
`

const Blog = props => {
	const { data } = props
	const posts = data && data.sanityBlogPage
	const { hero, blogPosts, seo } = posts

	return (
		<>
			<Meta pageTitle="Blog" props={seo} />
			<Hero {...hero} />
			<div className='subpage-container'>
				{blogPosts?.map((blogEntry, i) =>
					<article
						key={i}
						className='first:mt-0 mt-10 md:mt-[10%]'
					>
						<BlogCard {...blogEntry}/>
					</article>
				)}
			</div>
		</>
	);
}

export default Blog