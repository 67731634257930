import React from 'react'
import SanityImage from './SanityImage'
import { useImageSize } from '../hooks/useImageSize'
import { Link } from 'gatsby'

const BlogCard = ({ title, slug, seo, _rawHero }) => {
	const link = `/blog/${slug?.current}`
	const imageSize = useImageSize(0.5, 1, 'lg')

	return (
		<section id='blogCard'>
			<div className='flex flex-col md:flex-row'>
				<div className='flex flex-col min-h-full'>
					<Link to={link}>
						<h2 className='h4 pt-2 md:pt-0'>{title}</h2>
					</Link>
					<p className='body-sm pt-5 md:pt-10'>{seo?.description}</p>
					<Link
						to={link}
						className='h5 pt-5 md:pt-0 mt-auto'
					>
						Read more
					</Link>
				</div>
				<div className='order-first md:min-w-[50%] md:pl-5 md:order-none'>
					<div className='aspect-w-2 aspect-h-2'>
						<Link to={link}>
							<SanityImage
								loading='lazy'
								image={_rawHero}
								className='object-cover h-full w-full'
								maxWidth={imageSize}
							/>
						</Link>
					</div>
				</div>
			</div>
		</section>
	);
}

export default BlogCard;